import React from 'react';
import styles from './HolidayPopup.module.css';

export default function HolidayPopupModal({ isModalVisible, handleModalClose, message }) {
  if (!isModalVisible) return null;

  return (
    <div>
      {/* Darkened background */}
      <div className={styles.overlay} onClick={handleModalClose}></div>

      {/* Modal */}
      <div className={styles.modal}>
        <p className={styles.modalMessage}>
          {message}
        </p>
        <button onClick={handleModalClose} className={styles.modalButton}>
          OK
        </button>
      </div>
    </div>
  );
}
