import React, { useState } from 'react'
import { useEffect } from 'react'
import InputMask from 'react-input-mask'
import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { Styles } from './Styles'
import { Script } from './Script'
import { getDomain } from '../../utils'
import HolidayPopupModal from '../HolidayPopupModal/HolidayPopupModal'
import { useApiData } from '../../context/apiContext'

export default function GenesysTextWithUs({ show, setShow, handleShowMessagingTool, setShowWebTool }) {
    const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [holidayPopupStatus, setHolidayPopupStatus] = useState(false);
    const [holidayPopupMessage, setHolidayPopupMessage] = useState(null);
    const { apiData3, apiData4 } = useApiData();
    
    const handleModalClose = () => {
        setIsModalVisible(false);
    };

    const {
        sendText,
        webMessaging,
        phone,
        setPhone,
        textSent,
        setTextSent,
        mobileApp,
        setMobileApp,
        loading,
        setLoading
    } = Script(handleShowMessagingTool)
    if (typeof window === 'object') {
        window.setMobileApp = (e) => setMobileApp(e)
    }
    useEffect(() => {
        let timer;
        if (textSent) {
            timer = setTimeout(() => {
                setTextSent(false);
                setPhone('');
            }, 600000); // 10 minutes in milliseconds
        }
        return () => clearTimeout(timer);
    }, [textSent, setPhone, setTextSent]);

      useEffect(() => {
        if (apiData3 && apiData4) {
          function fetchWebsiteMaintainence() {
            var holidayPopupStatusResponse = apiData3
            var holidayPopupMessageResponse = apiData4
    
            const status = holidayPopupStatusResponse?.data
            if (status.Result == "Active") {
                setHolidayPopupStatus(true)
                setHolidayPopupMessage(holidayPopupMessageResponse?.data?.Result)
                setIsModalVisible(true)
            }
          }
          fetchWebsiteMaintainence()
        }
      }, [apiData3, apiData4]);
    return (
        show && (
            <div className="genesys-text-with-us" style={Styles.container}>
                <HolidayPopupModal 
                    isModalVisible={isModalVisible} 
                    handleModalClose={handleModalClose} 
                    message={holidayPopupMessage} 
                />
                <div style={Styles.innerContainer}>
                    <p
                        onClick={() => {
                            setShow(false)
                            setShowWebTool(true)
                            window?.ReactNativeWebView?.postMessage('minimized')
                        }}
                        style={Styles.cancelBtn(mobileApp)}
                    >
                        X
                    </p>
                    <img
                        alt="chat"
                        src="https://assets.cure.com/img/main-site/png/chat.png"
                        style={{
                            width: '45px'
                        }}
                    ></img>
                    <p style={Styles.headerImg}>TEXT WITH US</p>
                </div>
                {!textSent ? (
                    <div>
                        <div style={Styles.upperSect}>
                            <p style={Styles.upperSectTxt(mobileApp)}>
                                Our Live Chat just got even better. With text-based chat, you will receive alerts just like any other
                                text message on your phone.
                            </p>
                            <p style={Styles.upperSectTxt(mobileApp)}>
                                Let's start texting! Enter your phone number and we will send you a text.
                            </p>
                            <p style={{ ...Styles.upperSectTxt(mobileApp), marginBlockEnd: '0' }}>
                                {mobileApp ? 'Need to stay in the app?' : 'Need to stay on your web browser?'}
                            </p>
                            <div className="genesys-copy" style={{ width: '100%', marginBottom: '0px', marginTop: '-15px' }}>
                                <div
                                    style={Styles.link}
                                >
                                    <a
                                        onClick={() => {
                                            webMessaging()
                                            setLoading(true)
                                        }}
                                        style={{ color: '#1468A6', textDecoration: 'underline' }}
                                    >
                                        Click here
                                    </a>
                                    {loading && <Spin indicator={antIcon} />}
                                </div>
                            </div>

                            <InputMask
                                className="input-text phone-number"
                                mask="(999) 999-9999"
                                onChange={e => setPhone(e.target.value)}
                                placeholder="(___) ___ ____"
                                value={phone}
                                style={Styles.inputPhone(mobileApp)}
                            />
                            <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#c5e6f4', color: '#2e69b8', width: 'calc(100% + 30px)', marginLeft: '-15px', fontWeight: 'bold' }}>
                                <div style={{ margin: '20px 15px' }}>
                                    Please Note: Claims and customers interested in a new CURE policy can't be handled via chat. To report a claim, {' '}
                                    <span
                                        style={{
                                            color: '#4bb9ee',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontWeight: 'inherit',
                                        }}
                                        onClick={() => {
                                            if (mobileApp) {
                                                window.ReactNativeWebView.postMessage('redirect');
                                            } else {
                                                window.open("https://" + window.location.hostname + "/claims/fnol-form", "_blank", "noopener noreferrer");
                                            }
                                        }}
                                    >
                                        click here
                                    </span>. To start a quote, {' '}
                                    <span
                                        style={{
                                            color: '#4bb9ee',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            fontWeight: 'inherit',
                                        }}
                                        onClick={() => {
                                            if (mobileApp) {
                                                window.ReactNativeWebView.postMessage('redirect');
                                            } else {
                                                window.open('https://' + getDomain('quote') + '/default.aspx', "_blank", "noopener noreferrer");
                                            }
                                        }}
                                    >
                                        click here
                                    </span>.
                                </div>
                            </div>
                            <button
                                disabled={phone.length < 10 || phone.includes('_')}
                                type="button"
                                onClick={() => sendText()}
                                className="ant-btn ant-btn-default register register-form-button"
                                style={Styles.sendBtn(mobileApp)}
                            >
                                TEXT NOW
                            </button>
                        </div>
                        <div
                            style={{
                                padding: '20px',
                                paddingBottom: '15px',
                                paddingTop: '0px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                fontSize: '10px',
                                gap: '15px'
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                By entering your phone number and clicking the CHAT NOW button, you agree to receive transactional text
                                messages from CURE, and also agree to our{' '}
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={mobileApp ? "" : "https://cure.com/terms-conditions"}
                                    style={{ color: 'black', textDecoration: 'underline' }}
                                    onClick={() => { window.ReactNativeWebView.postMessage('redirect') }}
                                >
                                    Terms of Service
                                </a>
                                . Msg&DataRatesMayApply
                            </p>
                        </div>
                    </div>
                ) : (
                    <div
                        style={{
                            padding: '20px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <p
                            style={{
                                color: '#1468A6',
                                fontWeight: 'bold',
                                fontSize: '16px',
                                marginBottom: '0px'
                            }}
                        >
                            We've sent you a text. Reply to start a conversation.
                        </p>
                    </div>
                )}
            </div>
        )
    )
}
