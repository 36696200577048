import { createContext, useContext, useState } from 'react';

const ApiDataContext = createContext();

export const ApiDataProvider = ({ children }) => {
  const [apiData1, setApiData1] = useState(null);
  const [apiData2, setApiData2] = useState(null);
  const [apiData3, setApiData3] = useState(null);
  const [apiData4, setApiData4] = useState(null);

  const websiteStatus = (newData) => {
    setApiData1(newData);
  };

  const websiteMessage = (newData) => {
    setApiData2(newData);
  };

  const holidayPopupStatus = (newData) => {
    setApiData3(newData);
  };

  const holidayPopupMessage = (newData) => {
    setApiData4(newData);
  };

  return (
    <ApiDataContext.Provider value={{ apiData1, apiData2, apiData3, apiData4, websiteStatus, websiteMessage, holidayPopupStatus, holidayPopupMessage }}>
      {children}
    </ApiDataContext.Provider>
  );
};

export const useApiData = () => {
  return useContext(ApiDataContext);
};
