export const getDomain = type => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'dev.cure.com':
        if (type === 'account') {
          return 'myaccountdev.cure.com'
        } else if (type === 'payments') {
          return 'paymentsdev.cure.com'
        } else if (type === 'chat') {
          return 'chatdev.cure.com'
        } else {
          return 'getquotedev.cure.com'
        }
      case 'localhost':
        if (type === 'account') {
          return 'myaccountdev.cure.com'
        } else if (type === 'payments') {
          return 'paymentsdev.cure.com'
        } else if (type === 'chat') {
          return 'chatstg.cure.com'
        } else {
          return 'getquotedev.cure.com'
        }
      case 'staging.cure.com':
        if (type === 'account') {
          return 'myaccountstg.cure.com'
        } else if (type === 'payments') {
          return 'paymentsstg.cure.com'
        } else if (type === 'chat') {
          return 'chatstg.cure.com'
        } else {
          return 'getquotestg.cure.com'
        }
      case 'prepublish.cure.com':
        if (type === 'account') {
          return 'myaccountprep.cure.com'
        } else if (type === 'payments') {
          return 'paymentsprep.cure.com'
        } else if (type === 'chat') {
          return 'chatprep.cure.com'
        } else {
          return 'getquoteprep.cure.com'
        }
      case '34.133.222.14':
        if (type === 'account') {
          return 'myaccountstg.cure.com'
        } else if (type === 'payments') {
          return 'paymentsstg.cure.com'
        } else {
          return 'getquotestg.cure.com'
        }
      case 'gw.cure.com':
        if (type === 'account') {
          return 'myaccountgw.cure.com'
        } else if (type === 'payments') {
          return 'paymentsgw.cure.com'
        } else if (type === 'chat') {
          return 'chatstg.cure.com'
        } else {
          return 'getquotegw.cure.com'
        }
      default:
        if (type === 'account') {
          return 'myaccount.cure.com'
        } else if (type === 'payments') {
          return 'payments.cure.com'
        } else if (type === 'chat') {
          return 'chat.cure.com'
        } else {
          return 'getquote.cure.com'
        }
    }
  }
}

export const getHostName = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'dev.cure.com':
        return 'uat'
      case 'localhost':
        return 'uat'
      case 'staging.cure.com':
        return 'uat'
      case 'prepublish.cure.com':
        return 'uat'
      case '34.133.222.14':
        return 'uat'
      case 'gw.cure.com':
        return 'uat'
      default:
        return 'prod'
    }
  }
}

export const getSiteEnvironment = () => {
  if (typeof window !== 'undefined') {
    switch (window.location.hostname) {
      case 'www.cure.com':
      case 'cure.com':
        return 'Production'
      case 'prepublish.cure.com':
        return 'Prepublish'
      default:
        return 'Staging'
    }
  }
}

export const getAPIEndpoint = () => {
  if (typeof window !== 'undefined' && process && process.env) {
    switch (window.location.hostname) {
      case 'dev.cure.com':
        return process.env.NEXT_PUBLIC_API_DEV_URL
      case 'localhost':
        return process.env.NEXT_PUBLIC_API_DEV_URL //process.env.NEXT_PUBLIC_API_UAT_URL
      case 'staging.cure.com':
        return process.env.NEXT_PUBLIC_API_UAT_URL
      case 'prepublish.cure.com':
        return process.env.NEXT_PUBLIC_API_PREP_URL
      case 'gw.cure.com':
        return process.env.NEXT_PUBLIC_API_GW_URL
      case '34.133.222.14':
        return process.env.NEXT_PUBLIC_API_UAT_URL
      default:
        return process.env.NEXT_PUBLIC_API_URL
    }
  }

}

export const getTestingEnvToken = () => {
  if (typeof window !== 'undefined' && process && process.env) {
        return "44874035-568c-4db4-aed0-69a0a3203cd6"
  }else{
    return "";
  }
}
